<template>
  <div class="public Intelligent">
    <el-container>
      <!-- <el-aside width="220px" class="left-box">
        <el-menu :default-openeds="['1']">
          <el-menu-item index="1">
            <span slot="title">智能分析</span>
          </el-menu-item>
        </el-menu>
      </el-aside> -->
      <el-container>
        <el-main class="detail-box">
          <router-link to="/Intelligent" tag="div">
            <el-button
              class="goback"
              size="small"
              type="text"
              icon="el-icon-arrow-left"
              >返回</el-button
            >
          </router-link>
          <h2 class="title">
            <span>预付消费卡监管明细报告</span>
          </h2>
          <div class="text-info">
            <p>
              {{
                `本报告由${this.strs}单用途预付消费卡协同监管平台出具，依据截止报告时间系统记录的信息生成。报告中的信息均由相关报数机构和信息主体提供，${this.strs}单用途预付消费卡协同监管平台不保证其真实性和准确性，但承诺在信息整合、汇总、展示的全过程中保持客观、中立的地位。 本报告仅向预付卡监管部门工作人员提供，不得作为金融机构的授信依据，请妥善保管。因保管不当造成信息泄露的，${this.strs}单用途预付消费卡协同监管平台不承担相关责任。`
              }}
            </p>
          </div>
          <div class="conbox">
            <h3 class="sub-title">
              <i class="icon-tit1"></i>
              <span>基本信息</span>
            </h3>
            <div class="sub-conbox">
              <h4 class="item-title"><span>工商信息</span></h4>
              <table class="table">
                <tr>
                  <th>经营者名称</th>
                  <td>{{ basicInfo.authority }}</td>
                  <th>统一社会信用代码</th>
                  <td>{{ basicInfo.creditNo }}</td>
                </tr>
                <tr>
                  <th>类型</th>
                  <td>{{ basicInfo.companyType }}</td>
                  <th>法定代表人</th>
                  <td>{{ basicInfo.legalPerson }}</td>
                </tr>
                <tr>
                  <th>注册资本</th>
                  <td>{{ basicInfo.capital }}</td>
                  <th>成立日期</th>
                  <td>{{ basicInfo.establishDate }}</td>
                </tr>
                <tr>
                  <th>营业期限自</th>
                  <td>{{ basicInfo.operationEndDate }}</td>
                  <th>营业期限至</th>
                  <td>{{ basicInfo.operationStartDate }}</td>
                </tr>
                <tr>
                  <th>登记状态</th>
                  <td>{{ basicInfo.companyStatus }}</td>
                  <th>住所</th>
                  <td>{{ basicInfo.companyAddress }}</td>
                </tr>
                <tr>
                  <th>经营范围</th>
                  <td colspan="3">{{ basicInfo.businessScope }}</td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>主要出资人信息</span></h4>
              <table class="table">
                <tr>
                  <th>股东</th>
                  <td>--</td>
                  <th>出资比例</th>
                  <td>--</td>
                </tr>
                <tr>
                  <th>股东</th>
                  <td>--</td>
                  <th>出资比例</th>
                  <td>--</td>
                </tr>
                <tr>
                  <th>股东</th>
                  <td>--</td>
                  <th>出资比例</th>
                  <td>--</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="conbox">
            <h3 class="sub-title">
              <i class="icon-tit2"></i>
              <span>公共信息</span>
            </h3>
            <div class="text-info">
              <p>
                经营者主体成立于<span>{{ publicInfo.establishDate }}</span
                >，经营<span>{{ publicInfo.manageTime }}</span
                >年， 登记状态正常，报告期内，经营者住房公积金缴纳人数<span>{{
                  publicInfo.payNum
                }}</span
                >人， 月缴金额<span>{{ publicInfo.payMonthMoney }}</span
                >元，缴纳状态<span>{{
                  publicInfo.payStatus ? publicInfo.payStatus : "--"
                }}</span
                >。 当前经营者法定代表人个人公积金逾期<span>{{
                  publicInfo.payTime
                }}</span
                >个月，逾期金额<span>{{ publicInfo.payMoney }}</span
                >元。 目前经营者有<span>{{ publicInfo.manageNum }}</span
                >条违约记录， 电力欠费<span>{{ publicInfo.electricMoney }}</span
                >元，逾期<span>{{ publicInfo.electricTime }}</span
                >个月； 燃气欠费<span>{{ publicInfo.gasMoney }}</span
                >元，逾期<span>{{ publicInfo.gasTime }}</span
                >个月； 截止<span>{{ publicInfo.waterTime }}</span
                >，水务累计欠费<span>{{ publicInfo.waterMoney }}</span
                >元。
              </p>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>电力欠费信息</span></h4>
              <table class="table" v-if="publicOweInfo.electrics != ''">
                <tr v-for="(item, i) in publicOweInfo.electrics" :key="i">
                  <th>电费年月</th>
                  <td>{{ item.electricTime }}</td>
                  <th>欠费金额</th>
                  <td>{{ item.electricMoney }}</td>
                </tr>
              </table>
              <div v-else>暂无数据</div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>水务欠费信息</span></h4>
              <table class="table">
                <tr>
                  <th>用水年月</th>
                  <td>{{ publicOweInfo.waterTime }}</td>
                  <th>欠费金额</th>
                  <td>{{ publicOweInfo.waterMoney }}</td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>燃气欠费信息</span></h4>
              <table class="table" v-if="publicOweInfo.gas != ''">
                <tr v-for="(item, i) in publicOweInfo.gas" :key="i">
                  <th>用气年月</th>
                  <td>{{ item.gasTime }}</td>
                  <th>欠费金额</th>
                  <td>{{ item.gasMoney }}</td>
                </tr>
              </table>
              <div v-else>暂无数据</div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>住房公积金缴存单位信息</span></h4>
              <table class="table">
                <tr>
                  <th>单位缴交状态</th>
                  <td>--</td>
                  <th>末次汇缴月份</th>
                  <td>{{ publicOweInfo.payTime }}</td>
                </tr>
                <tr>
                  <th>当前实缴人数</th>
                  <td>{{ publicOweInfo.payNum }}</td>
                  <th>月交金额</th>
                  <td>{{ publicOweInfo.payMoney }}</td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>公积金个贷逾期信息</span></h4>
              <div v-if="publicOweInfo.overdues != ''">
                <table
                  class="table"
                  style="margin-bottom: 15px"
                  v-for="(item, i) in publicOweInfo.overdues"
                  :key="i"
                >
                  <tr>
                    <th>逾期期数</th>
                    <td>{{ item.overduePeriod }}</td>
                    <th>逾期金额</th>
                    <td>{{ item.pastAmount }}</td>
                  </tr>
                  <tr>
                    <th>贷款年限</th>
                    <td>{{ item.loanTerm }}</td>
                    <th>贷款余额</th>
                    <td>{{ item.loanBalance }}</td>
                  </tr>
                  <tr>
                    <th>贷款总额</th>
                    <td>{{ item.loanTotal }}</td>
                    <th>贷款日期</th>
                    <td>{{ item.loanDate }}</td>
                  </tr>
                </table>
              </div>
              <div v-else>暂无数据</div>
            </div>
          </div>
          <div class="conbox">
            <h3 class="sub-title">
              <i class="icon-tit3"></i>
              <span>经营信息</span>
            </h3>
            <div class="text-info">
              <p>
                经营者主体于<span>{{ manageInfo.time }}</span
                >完成信息对接和资金存管开户，于<span>{{
                  manageInfo.startTime
                }}</span
                >首次有发卡交易记录， 报告期内，共有<span
                  >{{ manageInfo.branchNum }}家</span
                >分店经营发卡业务，涉及会员<span>{{ manageInfo.vipNum }}</span
                >人， 发卡数量<span>{{ manageInfo.cardNum }}</span
                >笔，发卡金额<span>{{ manageInfo.cardMoney }}</span
                >元， 已兑付金额<span>{{ manageInfo.dfMoney }}</span
                >元，兑付比为<span>{{ manageInfo.dfRatio }}</span
                >， 资金管理方式为<span>{{ manageInfo.type }}</span
                >，存管账户为<span>0</span>元，目前风险状况为<span>{{
                  manageInfo.fxzk
                }}</span
                >。
                <!-- 当前应存管发卡金额为<span>{{manageInfo.cgfkMoney}}</span>元， -->
                共有<span>{{ manageInfo.tsNum }}</span
                >次投诉，<span>{{ manageInfo.cfNum }}</span
                >条处罚记录， <span>{{ manageInfo.fxczNum }}</span
                >条风险处置记录。
              </p>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>经营者信息</span></h4>
              <table class="table code-table">
                <tr>
                  <td rowspan="6" class="code-img-box">
                    <h5>预付码状态</h5>
                    <div class="code-img">
                      <img :src="baseUrl + operatorInfo.safeCodeImg" />
                      <span>{{ operatorInfo.safeCode }}</span>
                    </div>
                    <p>数据更新至<br />{{ operatorInfo.safeCodeTime }}</p>
                  </td>
                  <th>经营者名称</th>
                  <td>{{ operatorInfo.busName || "--" }}</td>
                  <th>店铺数量</th>
                  <td>{{ operatorInfo.busNum || "--" }} 家</td>
                </tr>
                <tr>
                  <th>员工人数</th>
                  <td>{{ operatorInfo.people || "--" }} 人</td>
                  <th>所在地区</th>
                  <td>{{ operatorInfo.address || "--" }}</td>
                </tr>
                <tr>
                  <th>所属行业</th>
                  <td>{{ operatorInfo.industry || "--" }}</td>
                  <th>类型</th>
                  <td>{{ operatorInfo.type || "--" }}</td>
                </tr>
                <tr>
                  <th>资金管理方式</th>
                  <td>{{ operatorInfo.fundMode || "--" }}</td>
                  <th>风险状况</th>
                  <td>{{ operatorInfo.risk || "--" }}</td>
                </tr>
                <tr>
                  <th>会员数</th>
                  <td>{{ operatorInfo.vipNum || "--" }} 人</td>
                  <th>风险处置</th>
                  <td>{{ operatorInfo.damaged || "--" }}</td>
                </tr>
                <tr>
                  <th>风险处置结果</th>
                  <td colspan="3">{{ operatorInfo.damagedResult || "--" }}</td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>预付交易状况</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>交易笔数（笔）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cardNum }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>交易金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cardMoney }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>兑付金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cashingMoney }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>兑付比（%）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cashingRatio }}</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio5">
                  <el-radio-button
                    v-for="item in radioData"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChart
                  :chartData="newlineChartData"
                  :legendData="['交易笔数', '交易金额', '兑付金额', '兑付比']"
                  :units="['笔', '元', '元', '%']"
                  :yAxis="['', '%']"
                  :yAxisIndex="['0', '0', '0', '1']"
                ></LineChart>
              </div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>资金监管状况</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>应存管发卡金额（元）</p>
                  <div class="box bg2">
                    <i></i><i></i>
                    <p>{{ moneyInfo.tranMoney | numFilter }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>存管资金（元）</p>
                  <div class="box bg2">
                    <i></i><i></i>
                    <p>{{ moneyInfo.cgMoney | numFilter }}</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio2">
                  <el-radio-button
                    v-for="item in radioData"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChart
                  :chartData="lineChartData2"
                  :legendData="['应存管发卡金额', '存管资金']"
                  :units="['元', '元']"
                  :yAxis="['']"
                  :yAxisIndex="['0']"
                ></LineChart>
              </div>
              <div class="number-box">
                <div class="number-item">
                  <p>应保险发卡金额（元）</p>
                  <div class="box bg2">
                    <i></i><i></i>
                    <p>{{ insuredInfo.money }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>保额（元）</p>
                  <div class="box bg2">
                    <i></i><i></i>
                    <p>{{ insuredInfo.beMoney }}</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio3">
                  <el-radio-button
                    v-for="item in radioData"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChart
                  :chartData="lineChartData3"
                  :legendData="['应保险发卡金额', '保额']"
                  :units="['元', '元']"
                  :yAxis="['']"
                  :yAxisIndex="['0']"
                ></LineChart>
              </div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>投诉受理状况</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>被投诉（件）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ complainInfo.complaintNum }}</p>
                  </div>
                  <div class="right">
                    <p>已受理：{{ complainInfo.dealNum }}</p>
                    <p>待受理：{{ complainInfo.umDealNum }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>被处罚（次）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ complainInfo.punishNum }}</p>
                  </div>
                  <div class="right">
                    <p>黑名单：{{ complainInfo.blackNum }}</p>
                    <p>中止发卡：{{ complainInfo.stopNum }}</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio4">
                  <el-radio-button
                    v-for="item in radioData"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChartnew
                  :chartData="lineChartData4"
                  :legendData="['投诉']"
                  :units="['']"
                  :yAxis="['']"
                  :yAxisIndex="['0']"
                ></LineChartnew>
                <!-- <BarChart :chartData="barChartData" :legendData="['投诉', '处罚']"></BarChart> -->
              </div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>风险处置记录</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>处置日期</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ damagedInfo.time }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>涉及人数（人）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ damagedInfo.num }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>涉及金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ damagedInfo.money }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="sub-conbox">
              <h4 class="item-title"><span>预付合同签约情况</span></h4>
              <div class="idiot-one">
                <div class="box" v-for="item in forCodeData" :key="item.type">
                  <div class="box-left">
                    <div
                      class="box-leftchild"
                      v-for="(itemval, ind) in item.seriesData"
                      :key="ind"
                    >
                      {{ itemval.name }}
                      <div>{{ itemval.value }}</div>
                      {{ itemval.valcom }}
                    </div>
                  </div>
                  <div class="box-right">
                    <Pieadvance
                      :chartData="{
                        seriesData: item.seriesData,
                      }"
                    ></Pieadvance>
                  </div>
                </div>
              </div>
            </div>

            <div class="sub-conbox">
              <h4 class="item-title"><span>预付消费结构</span></h4>
              <div class="idiot-one">
                <div
                  class="box"
                  v-for="item in forCodeDatatwo"
                  :key="item.type"
                >
                  <div class="fl box-left">
                    <div class="box-left-childtop">
                      {{ item.type == 1 ? "用户人群：" : "预付卡交易金额：" }}
                      <div class="box-left-childtopval">
                        {{ item.xfrq }}
                        <span class="box-left-childtopvalcom">{{
                          item.xfrqcom
                        }}</span>
                      </div>
                    </div>

                    <div class="box-left-childbottom">
                      <div
                        class="box-left-childbottomval"
                        v-for="(itemval, index) in item.seriesData"
                        :key="itemval.val"
                      >
                        <div
                          class="box-left-childbottomvalicon"
                          :style="{ background: forCodeDatacolor[index] }"
                        ></div>
                        <div>{{ itemval.name }}</div>
                        <div
                          class="box-left-childbottomvalpercentage"
                          :style="{ color: forCodeDatacolor[index] }"
                        >
                          {{ itemval.value }}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fr box-right">
                    <div class="box-url"></div>
                    <PieEpide
                      :chartData="{
                        seriesData: item.seriesData,
                        Gender: item.Gender,
                      }"
                      width="260px"
                      height="260px"
                    >
                    </PieEpide>
                  </div>
                </div>
              </div>
            </div>

            <div class="sub-conbox">
              <h4 class="item-title">
                <span
                  >{{
                    this.tabPosition2 == 1
                      ? "门店防疫"
                      : this.tabPosition2 == 2
                      ? "从业人员"
                      : "消费人员"
                  }}情况</span
                >
              </h4>
              <el-radio-group v-model="tabPosition2" class="tab-select-btn">
                <el-radio-button label="1">门店防疫</el-radio-button>
                <el-radio-button label="2">从业人员</el-radio-button>
                <el-radio-button label="3">消费人员</el-radio-button>
              </el-radio-group>
              <div class="echarts-but">
                <el-radio-group v-model="radio1">
                  <el-radio-button
                    v-for="item in radioData1"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <BarChart
                  :chartData="barChartData"
                  :legendData="['健康从业人员', '有症状从业人员']"
                ></BarChart>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script type="text/javascript">
import { baseURL } from "@/api/config";
import LineChart from "@/components/charts/line2"; // 四码折线图
import LineChartnew from "@/components/charts/line3"; // 四码折线图
import BarChart from "@/components/charts/bar";
import Pieadvance from "@/components/charts/Pieadvance.vue";
import PieEpide from "@/components/charts/pieHomeEpide";
import {
  basicInfo,
  publicInfo,
  publicOweInfo,
  manageInfo,
  operatorInfo,
  transactionsInfo,
  moneyInfo,
  insuredInfo,
  complainInfo,
  damagedInfo,
  contractStatistics,
  consumerStatistics,
  epidemicPreventionCumulative,
} from "@/api/intelligent";

export default {
  name: "IntelligentAnalysis",
  data() {
    return {
      baseUrl: baseURL,
      radio5: "1",
      radio1: "1",
      radio2: "1",
      radio3: "1",
      radio4: "1",
      tabPosition2: 1,
      forCodeDatacolor: ["#125fe0", "#208dfd", "#18bbff", "#00ff5a", "#c6ff00"],
      forCodeData: [
        {
          seriesData: [
            {
              name: "已签约",
              value: 0,
              valcom: "份",
            },
            {
              name: "未签约",
              value: 0,
              valcom: "份",
            },
          ],
        },
        {
          seriesData: [
            {
              name: "履约",
              value: 0,
              valcom: "份",
            },
            {
              name: "违约",
              value: 0,
              valcom: "份",
            },
          ],
        },
      ],

      forCodeDatatwo: [
        {
          seriesData: [
            {
              name: "儿童",
              value: 0,
            },
            {
              name: "少年",
              value: 0,
            },
            {
              name: "青年",
              value: 0,
            },
            {
              name: "中年",
              value: 0,
            },
            {
              name: "老年",
              value: 0,
            },
          ],
          xfrq: 0,
          xfrqcom: "人",
          man: 0,
          woman: 0,
          type: 1,
        },
        {
          seriesData: [
            {
              name: "儿童",
              value: 0,
            },
            {
              name: "少年",
              value: 0,
            },
            {
              name: "青年",
              value: 0,
            },
            {
              name: "中年",
              value: 0,
            },
            {
              name: "老年",
              value: 0,
            },
          ],
          xfrq: 0,
          xfrqcom: "元",
          man: 0,
          woman: 0,
          type: 2,
        },
      ],
      radioData: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      newlineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "交易笔数",
            data: [],
          },
          {
            name: "交易金额",
            data: [],
          },
          {
            name: "兑付金额",
            data: [],
          },
          {
            name: "兑付比",
            data: [],
          },
        ],
      },
      lineChartData2: {
        xAxisData: [],
        seriesData: [
          {
            name: "应存管发卡金额",
            data: [],
          },
          {
            name: "存管资金",
            data: [],
          },
        ],
      },
      lineChartData3: {
        xAxisData: [],
        seriesData: [
          {
            name: "应保险发卡金额",
            data: [],
          },
          {
            name: "保额",
            data: [],
          },
        ],
      },
      lineChartData4: {
        xAxisData: [],
        seriesData: [
          {
            name: "",
            data: "",
          },
        ],
      },
      lineChartData4Xdata: [],
      lineChartData4SeriesData: [],
      barChartData: {
        xAxisData: [2014, 2015, 2016, 2017, 2018, 2019],
        seriesData: [
          {
            name: "有症状从业人员",
            data: [1, 2, 3, 4, 5, 6],
          },
          {
            name: "有症状从业人员",
            data: [2, 3, 4, 5, 6, 7],
          },
        ],
      },
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      basicInfo: {
        authority: "",
        creditNo: "",
        companyType: "",
        legalPerson: "",
        capital: "",
        establishDate: "",
        operationEndDate: "",
        operationStartDate: "",
        companyStatus: "",
        companyAddress: "",
        businessScope: "",
      },
      publicInfo: {
        establishDate: "",
        manageTime: "",
        payNum: "",
        payMonthMoney: "",
        payStatus: "",
        payTime: "",
        payMoney: "",
        manageNum: "",
        electricMoney: "",
        electricTime: "",
        gasMoney: "",
        gasTime: "",
        waterTime: "",
        waterMoney: "",
      },
      publicOweInfo: {
        overdues: "",
        payMoney: "",
        payNum: "",
        payTime: "",
        gas: "",
        waterMoney: "",
        waterTime: "",
        electrics: "",
      },
      manageInfo: {},
      operatorInfo: {},
      transactionsInfo: {},
      moneyInfo: {},
      insuredInfo: {},
      complainInfo: {},
      damagedInfo: {},
      labels: {},
      citys: ["北京", "上海", "天津", "重庆", "香港", "澳门"],
      strs: "",
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  watch: {
    radio1: function (val) {
      this.getepidemicPreventionCumulative();
      // this.getTransactionsInfo();
    },
    radio2: function (val) {
      this.getMoneyInfo();
    },
    radio3: function (val) {
      this.getInsuredInfo();
    },
    radio4: function (val) {
      this.getComplainInfo();
    },
    radio5: function (val) {
      this.getTransactionsInfo();
    },
    tabPosition2: function (val) {
      this.getepidemicPreventionCumulative();
    },
  },
  mounted() {
    this.setLabels();
    this.intData();
  },
  methods: {
    setLabels() {
      this.labels = JSON.parse(window.sessionStorage.getItem("labels"));
      if (this.labels.pLabel) {
        if (this.citys.indexOf(this.labels.pLabel) !== -1) {
          this.strs = `${this.labels.pLabel}市`;
          if (this.labels.dLabel) {
            this.strs += this.labels.dLabel;
          }
        } else {
          this.strs = `${this.labels.pLabel}省`;
          if (this.labels.cLabel) {
            this.strs += this.labels.cLabel;
            if (this.labels.dLabel) {
              this.strs += this.labels.dLabel;
            }
          }
        }
      }
      // console.log(this.citys.indexOf(this.labels.pLabel) !== -1)
    },
    intData() {
      // console.log(this.$route)
      if (this.$route.query.riskBusId) {
        this.getBasicInfo();
        this.getPublicInfo();
        this.getPublicOweInfo();
        this.getManageInfo();
        this.getOperatorInfo();
        this.getTransactionsInfo();
        this.getMoneyInfo();
        this.getInsuredInfo();
        this.getComplainInfo();
        this.getDamagedInfo();
        this.getcontractStatistics();
        this.getconsumerStatistics();
        this.getepidemicPreventionCumulative();
      }
    },
    getBasicInfo() {
      basicInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.basicInfo;
        } else if (res.code == 0) {
          this.basicInfo = res.data;
        }
      });
    },
    getPublicInfo() {
      publicInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.publicInfo;
        } else if (res.code == 0) {
          this.publicInfo = res.data;
        }
      });
    },
    getPublicOweInfo() {
      publicOweInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.publicOweInfo;
        } else if (res.code == 0) {
          this.publicOweInfo = res.data;
        }
      });
    },
    getManageInfo() {
      manageInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.manageInfo = res.data;
      });
    },
    getOperatorInfo() {
      operatorInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.operatorInfo = res.data;
      });
    },
    getTransactionsInfo() {
      transactionsInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio5,
      }).then((res) => {
        this.transactionsInfo = res.data;
        this.newlineChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "交易笔数",
              data: res.data.nums,
            },
            {
              name: "交易金额",
              data: res.data.trans,
            },
            {
              name: "兑付金额",
              data: res.data.cashs,
            },
            {
              name: "兑付比",
              data: res.data.ratios,
            },
          ],
        };
      });
    },
    getMoneyInfo() {
      moneyInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio2,
      }).then((res) => {
        this.moneyInfo = res.data;
        this.lineChartData2 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "应存管发卡金额",
              data: res.data.trans,
            },
            {
              name: "存管资金",
              data: res.data.cgs,
            },
          ],
        };
      });
    },
    getInsuredInfo() {
      insuredInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio3,
      }).then((res) => {
        this.insuredInfo = res.data;
        this.lineChartData3 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "应保险发卡金额",
              data: res.data.moneys,
            },
            {
              name: "保额",
              data: res.data.bes,
            },
          ],
        };
      });
    },
    getComplainInfo() {
      complainInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio4,
      }).then((res) => {
        this.complainInfo = res.data;
        this.lineChartData4 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "投诉",
              data: res.data.complaints,
            },
          ],
        };
      });
    },
    getDamagedInfo() {
      damagedInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.damagedInfo = res.data;
      });
    },

    getcontractStatistics() {
      contractStatistics({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.forCodeData[0].seriesData[0].value = res.data.signNum;
        this.forCodeData[0].seriesData[1].value = res.data.unSignNum;
        this.forCodeData[1].seriesData[0].value = res.data.keepContractNum;
        this.forCodeData[1].seriesData[1].value = res.data.unKeepContractNum;
      });
    },
    getconsumerStatistics() {
      consumerStatistics({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.forCodeDatatwo = [
          {
            seriesData: [
              {
                name: "儿童",
                value: res.data.childConsumersRate,
              },
              {
                name: "少年",
                value: res.data.juvenileConsumersRate,
              },
              {
                name: "青年",
                value: res.data.youthConsumersRate,
              },
              {
                name: "中年",
                value: res.data.middleConsumersRate,
              },
              {
                name: "老年",
                value: res.data.oldConsumersRate,
              },
            ],
            xfrq: res.data.consumersNum,
            xfrqcom: "人",
            Gender:[
                {
                name: "男",
                value: res.data.maleConsumersRate,
              },
              {
                name: "女",
                value: res.data.femaleConsumersRate
              },
            ],
            // man: parseFloat(res.data.maleConsumersRate),
            // woman: parseFloat(res.data.femaleConsumersRate),
            type: 1,
          },
          {
            seriesData: [
              {
                name: "儿童",
                value: res.data.childAmountRate,
              },
              {
                name: "少年",
                value: res.data.juvenileAmountRate,
              },
              {
                name: "青年",
                value: res.data.youthAmountRate,
              },
              {
                name: "中年",
                value: res.data.middleAmountRate,
              },
              {
                name: "老年",
                value: res.data.oldAmountRate,
              },
            ],
            xfrq: res.data.amountNum,
            xfrqcom: "元",
             Gender:[
                {
                name: "男",
                value: res.data.maleAmountRate,
              },
              {
                name: "女",
                value: res.data.femaleAmountRate
              },
            ],
            // man: parseFloat(res.data.maleAmountRate),
            // woman: parseFloat(res.data.femaleAmountRate),
            type: 2,
          },
        ];
      });
    },
    getepidemicPreventionCumulative() {
      epidemicPreventionCumulative({
        infoType: this.tabPosition2,
        riskBusId: this.$route.query.riskBusId,
        type: this.radio1,
      }).then((res) => {
        this.barChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: this.tabPosition2 == 1? "正常门店数": this.tabPosition2 == 2? "正常从业人数": "正常消费人数",
              data: this.tabPosition2 == 1? res.data.normalShopNum: this.tabPosition2 == 2? res.data.normalEmployeeNum: res.data.normalConsumerNum,
            },
            {
              name: this.tabPosition2 == 1? "异常门店数": this.tabPosition2 == 2? "异常从业人数": "异常消费人数",
              data: this.tabPosition2 == 1? res.data.abnormalShopNum: this.tabPosition2 == 2? res.data.abnormalEmployeeNum: res.data.abnormalConsumerNum,
            },
          ],
        };
      });
    },
  },
  components: {
    LineChart,
    BarChart,
    LineChartnew,
    Pieadvance,
    PieEpide,
  },
};
</script>

<style lang="scss" scoped >
.Intelligent {
  .goback {
    font-size: 16px;
  }
  .left-box {
    width: 220px;
    background: url("../assets/imgs/insight-bg.jpg") no-repeat;
    padding-top: 10px;
  }
  .pie-box {
    background: url("../assets/imgs/pie-bg3.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;
          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              transparent,
              transparent,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            0 30 30 30;
          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }
      .pie-charts {
        float: left;
        width: 50%;
        height: 550px;
        box-sizing: border-box;
        background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        background-size: 55%;
        &:nth-child(3) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            30 30;
        }
      }
    }
  }
  .detail-box {
    .title {
      background: url("../assets/imgs/logo-line.png") no-repeat center bottom;
      padding-bottom: 25px;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 20px;
      span {
        line-height: 40px;
        font-size: 36px;
        color: transparent;
        background: linear-gradient(to bottom, #13caff, #2285ff);
        -webkit-background-clip: text;
      }
    }
    .text-info {
      color: rgba(44, 195, 255, 0.7);
      text-indent: 2em;
      line-height: 28px;
      font-size: 14px;
    }
    .conbox {
      border: 1px solid #ddd;
      border-image: -webkit-linear-gradient(#17688d, #040d23) 20 20;
      border-image: -moz-linear-gradient(#17688d, #040d23) 20 20;
      border-image: -o-linear-gradient(#17688d, #040d23) 20 20;
      border-image: linear-gradient(#17688d, #040d23) 20 20;
      border-radius: 10px;
      margin: 30px 0 0;
      background: linear-gradient(to bottom, #2bcaff1a, #0000, #0000);
      .text-info {
        margin: 40px 30px 50px;
        span {
          color: #ccc;
        }
      }
      .sub-title {
        font-weight: bold;
        text-align: center;
        background: url("../assets/imgs/title-bg.png") no-repeat center bottom;
        padding: 30px 0 20px;
        i {
          display: inline-block;
          width: 26px;
          height: 26px;
          position: relative;
          top: 4px;
          right: 10px;
        }
        .icon-tit1 {
          background: url("../assets/imgs/icon-tit2.png") no-repeat;
        }
        .icon-tit2 {
          background: url("../assets/imgs/icon-tit1.png") no-repeat;
        }
        .icon-tit3 {
          background: url("../assets/imgs/icon-tit3.png") no-repeat;
        }
        span {
          font-size: 24px;
          color: #30b5ff;
        }
      }
      .sub-conbox {
        position: relative;
        margin: 30px 20px;
        .item-title {
          line-height: 40px;
          margin-bottom: 20px;
          background: linear-gradient(to right, #12344d, #0000, #0000, #0000);
          border-left: 2px solid #55d0ff;
          span {
            margin-left: 20px;
            background: linear-gradient(to bottom, #18c3ff, #248aff);
            -webkit-background-clip: text;
            color: transparent;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
          }
        }
        .table {
          width: 100%;
          tr {
            border: 1px solid rgba(98, 156, 173, 0.2);
          }
          th,
          td {
            height: 50px;
            line-height: 25px;
            font-size: 14px;
          }
          th {
            color: #acbcc1;
            width: 170px;
            text-align: right;
            padding: 0 20px 0 20px;
            background: rgba(96, 152, 234, 0.2);
            box-sizing: border-box;
            font-weight: 500;
          }
          td {
            color: #fff;
            text-align: left;
            padding-left: 20px;
            min-width: 160px;
          }
        }
        .code-table {
          th {
            width: 130px;
          }
          .code-img-box {
            text-align: center;
            padding-right: 20px;
            h5 {
              color: #bad6de;
              font-size: 16px;
              font-weight: bold;
              margin: 15px 0 20px;
            }
            .code-img {
              margin-bottom: 20px;
              img {
                width: 110px;
                height: 110px;
              }
              span {
                display: block;
                color: #40d17d;
                font-size: 14px;
              }
            }
            p:last-child {
              line-height: 22px;
              font-size: 12px;
              color: #acbcc1;
            }
          }
        }
        .number-box {
          display: flex;
          justify-content: space-around;
          margin-bottom: 30px;
          .number-item {
            text-align: center;
            p {
              color: #fff;
              font-size: 16px;
              line-height: 30px;
              margin: 10px 0;
            }
            .box {
              position: relative;
              float: left;
              &.bg1 {
                width: 196px;
                height: 79px;
                background: url("../assets/imgs/number-bg.png") no-repeat;
              }
              &.bg2 {
                width: 274px;
                height: 79px;
                background: url("../assets/imgs/number2-bg.png") no-repeat;
              }
              i {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 0;
                &:nth-child(1) {
                  top: 0;
                  left: 0;
                  background: url("../assets/imgs/number-left-bg.png") no-repeat
                    left bottom;
                }
                &:nth-child(2) {
                  top: 0;
                  right: 0;
                  background: url("../assets/imgs/number-right-bg.png")
                    no-repeat right top;
                }
              }
              p {
                position: relative;
                z-index: 9;
                line-height: 79px;
                font-size: 28px;
                font-weight: bold;
                color: #5bd5f7;
                margin: 0;
              }
            }
            .right {
              position: relative;
              left: -21px;
              float: left;
              background: url("../assets/imgs/arrow-right.png") no-repeat left
                center;
              p {
                line-height: 24px;
                text-align: left;
                margin-left: 55px;
                color: #5bd5f7;
                font-size: 14px;
              }
            }
          }
        }

        .charts {
          width: 100%;
          height: 400px;
          margin-top: 10px;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
  .idiot-one {
    display: flex;
    justify-content: space-around;
    height: 300px;
    margin-bottom:30px;
    // border: 1px solid #17688d;
    // background: linear-gradient(
    //   to bottom,
    //   rgba(43, 202, 255, 0.1),
    //   #0000,
    //   rgba(43, 202, 255, 0.1)
    // );

    .box {
      width: 49.8%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .box-left {
        width: 100%;
        position: relative;
        .box-leftchild {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: white;
          & > div {
            font-size: 26px;
            color: #208dfd;
            height: 35px;
            line-height: 30px;
            margin: 0 20px;
          }
        }
        .box-left-childtop {
          font-size: 16px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: left;
          .box-left-childtopval {
            font-size: 24px;
            color: #208dfd;
            text-align: left;
          }
        }
        .box-left-childbottom {
          width: 200px;
          height: 66px;
          margin-left: 68px;
          flex-wrap: wrap;
          position: absolute;
          right: 0;
          top: 90px;
          .box-left-childbottomval {
            width: 50%;
            height: 22px;
            font-size: 12px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 5px;
            .box-left-childbottomvalicon {
              width: 8px;
              height: 8px;
              border-radius: 8px;
            }
            .box-left-childbottomvalpercentage {
              font-size: 14px;
            }
          }
        }
      }

      .box-right {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(2) {
        .box-header span {
          color: #18bbff;
        }
      }
    }

    .box:nth-child(1) {
      border-right: 1px solid transparent;
      border-image: linear-gradient(transparent, #17688d, transparent) 0 30;
    }
  }
}
</style>
