<template>
  <div ref="chart" :style="{ height: height, width: width }"></div>
</template>
<script>
import { debounce } from "lodash";
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        xAxisData: [],
        seriesData: [],
      },
    },
    legendData: {
      type: Array,
      default: [],
    },
    units: {
      type: Array,
      default: [],
    },
    yAxis: {
      type: Array,
      default: [],
    },
    yAxisIndex: {
      type: Array,
      default: 0,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      color: [
        "rgba(35,142,255,0.7)",
        "rgba(62,235,255,0.7)",
        "rgba(255,234,0,0.7)",
        "rgba(52,250,89,0.7)",
        "rgba(116,70,256,0.7)",
      ],
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },

  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      let arr = _this.chartData.seriesData || [];
      let seriesData = [];
      arr.forEach((item, i) => {
        seriesData.push({
          name: item.name,
          yAxisIndex: _this.yAxisIndex[i],
          type: "line",
          showSymbol: false,
          symbol: "circle",
          smooth: true,
          lineStyle: {
            color:
              item.name.indexOf("交易笔数") != -1
                ? _this.color[0]
                : item.name.indexOf("交易金额") != -1
                ? _this.color[1]
                : item.name.indexOf("未兑付金额") != -1
                ? _this.color[2]
                : item.name.indexOf("兑付比") != -1
                ? _this.color[3]
                : _this.color[i],
            width: 1,
          },
          itemStyle: {
            color:
              item.name.indexOf("交易笔数") != -1
                ? _this.color[0]
                : item.name.indexOf("交易金额") != -1
                ? _this.color[1]
                : item.name.indexOf("未兑付金额") != -1
                ? _this.color[2]
                : item.name.indexOf("兑付比") != -1
                ? _this.color[3]
                : _this.color[i],
          },
          label: {
            formatter: "{a}" + item.unit,
          },
          data: item.data,
        });
      });
      let options = {
        tooltip: {
          transitionDuration: 0,
          trigger: "axis",
          padding: 20,
          backgroundColor: "rgba(238,238,238,0.6)",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dashed",
              color: "rgba(102,163,215,0.3)",
            },
          },
          textStyle: {
            color: "#232a24",
          },
          formatter: function (params) {
            //console.log(params)
            let html = "";
            html += params[0].name + "<br>";
            params.forEach((res, i) => {
              //html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>'+_this.units[i]+'<br>'
              if (res.seriesName.indexOf("笔") != -1) {
                html +=
                  res.marker +
                  res.seriesName +
                  ":<span>" +
                  res.value +
                  "</span>笔<br>";
              } else if (res.seriesName.indexOf("金额") != -1) {
                html +=
                  res.marker +
                  res.seriesName +
                  ":<span>" +
                  res.value +
                  "</span>元<br>";
              } else if (res.seriesName.indexOf("比") != -1) {
                html +=
                  res.marker +
                  res.seriesName +
                  ":<span>" +
                  res.value +
                  "</span>%<br>";
              } else {
                html +=
                  res.marker +
                  res.seriesName +
                  ":<span>" +
                  res.value +
                  "</span>" +
                  _this.units[i] +
                  "<br>";
              }
            });
            return html;
          },
        },
        title: {
          // text: '需求资源数',
          textStyle: {
            color: "#507998",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
        grid: {
          left: "5%",
          right: "7%",
          bottom: "5%",
          top: "20%",
          containLabel: true,
        },
        // legend: {
        //   top: "5%",
        //   right: "5%",
        //   itemGap: 5,
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   icon: "circle",
        //   data: _this.legendData,
        //   textStyle: {
        //     fontSize: 12,
        //     color: "#232a24",
        //   },
        // },
        xAxis: {
          data: _this.chartData.xAxisData,
          splitNumber: 7,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#ececec",
            },
          },
          axisLine: {
            lineStyle: {
              width: 2,
              color: "#ececec",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: 12,
              color: "#232a24",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [],
        // '资源申请数','已提供资源数','需求项超时数'
        series: seriesData,
      };
      this.yAxis.map((item, i) => {
        if (item == "%") {
          options.yAxis.push({
            //name:item,
            min: 0,
            max: 100,
            scale: true,
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#ececec",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              formatter: "{value}" + item,
              textStyle: {
                fontSize: 12,
                color: "#232a24",
              },
            },
            axisTick: {
              show: false,
            },
          });
        } else {
          options.yAxis.push({
            //name:item,
            min: null,
            max: null,
            scale: true,
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#ececec",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                fontSize: 12,
                color: "#232a24",
              },
            },
            axisTick: {
              show: false,
            },
          });
        }
      });
      // 绘制图表
      this.chart.setOption(options);
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>


